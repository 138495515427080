import { useState } from "react";
import { App } from "../object/App";

export const useApp = () => {
  const getApp = () => {
    const TOAppString = localStorage.getItem("JRA_app") ?? "";
    let TOApp: App[] = [{ uid: "", name: "", url: "", isJraApp: false }];
    if(TOAppString !== "" && typeof TOAppString === "string" && TOAppString !== "undefined")
      TOApp = JSON.parse(TOAppString)
    else if (TOAppString !== "" && TOAppString !== "undefined")
      TOApp = TOAppString
    return TOApp
  };
  const [ TOApp, setTOApp ] = useState<App[]>(getApp());
  
  const saveApp = (TOApp:App[]) => {
    localStorage.setItem("JRA_app", JSON.stringify(TOApp))
    setTOApp(TOApp);
  };

  const removeTOApp = () => {
    localStorage.removeItem('JRA_app')
  };

  return { TOApp,  setTOApp: saveApp, removeTOApp };
};
import style from './noMatch.module.scss';
import { useNavigate } from "react-router-dom";

function NoMatch() {
    const navigate = useNavigate();
    setTimeout(()=>{
        navigate('/Accueil', {replace:true});
    }, 3000)
    
    return (
        <>
          <div className={style.background}>
            <div className="backsquare">
                <h1>Oops, vous êtes perdu</h1>
                La page que vous souhaitez afficher n'existe pas/plus. Vous allez être redirigé vers l'accueil dans un instant.
                <br/>
                Si le problème persiste, merci de contacter <a href="mailto:">dev@autobonplan.com</a>
            </div>
          </div>
        </>
      );
}


export default NoMatch;
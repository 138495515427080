export interface Law {
  uid: string;
  name: string;
  description: string;
  appUid: string;
  roleUid?: string;
}

export const userConfig ="66dff497c29ab5.03660608";
export const salepointConfig ="66dff497c29bb9.92373769";
export const popinConfig ="66dff497c29c40.62108422";
export const newsConfig ="66dff497c29cc2.95932141";
import React from 'react';
import FormTinyPopup from './FormTinyPopup';

interface SalepointPopupChildrensProps {
    handleButton: (state: number, comment?: string) => Promise<boolean>
    info: boolean
    setInfo: React.Dispatch<React.SetStateAction<boolean>>
    reject: boolean
    setReject: React.Dispatch<React.SetStateAction<boolean>>
    comment: string
    setComment: React.Dispatch<React.SetStateAction<string>>
    formRefState: React.RefObject<HTMLFormElement>
    errorMessages: Record<string, string>
  }
    const investTinyPopupChildrens = ({handleButton,
                                    info,
                                    setInfo,
                                    reject,
                                    setReject,
                                    comment,
                                    setComment,
                                    formRefState,
                                    errorMessages} :SalepointPopupChildrensProps) => {

                 
    return (
            <FormTinyPopup  handleButton={handleButton}
                            info={info}
                            setInfo={setInfo}
                            reject={reject}
                            setReject={setReject}
                            comment={comment}
                            setComment={setComment}
                            formRefState={formRefState}
                            errorMessages={errorMessages}/>
    )
}
export default investTinyPopupChildrens;
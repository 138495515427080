import clsx from 'clsx';
import { Button, Typography } from '@mui/material';
import style from './modal.module.scss';
import { Modal } from '@mui/base/Modal';
import { ReactComponent as Close } from '../../pages/assets/closeWindows.svg';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import CheckIcon from '@mui/icons-material/Check';
import React, { forwardRef, useEffect, useState } from 'react';
import { OverridableComponent } from '@mui/material/OverridableComponent';

interface ModalFormComponentProps {
  onFormSubmitSuccess: () => void;
  onClose: () => void;
  modalOpen: boolean;
  title: string | null;
  buttonText: string | null;
  buttonIcon: JSX.Element | null;
  childrenForm: JSX.Element[] | undefined;
  formEvent: (() => Promise<boolean>)[] | (() => Promise<boolean>) | undefined;
}

export default function ModalFormComponent({ 
  onFormSubmitSuccess,
  onClose,
  modalOpen,
  title = null,
  buttonText = null,
  buttonIcon = null,
  childrenForm,
  formEvent }: ModalFormComponentProps) {

  const [formCount, setFormCount] = useState(0);
  const [totalFormCount, setTotalFormCount] = useState(100);

  useEffect(() => {
    if (childrenForm)
      setTotalFormCount(childrenForm.length - 1)
  }, [childrenForm]);

  useEffect(() => {
    setFormCount(0)
  }, [modalOpen]);

  const onClickPrevButton = () => {
    setFormCount(formCount - 1)
  }
  const onClickNextButton = async () => {
    if (Array.isArray(formEvent)) {
      if (await formEvent[formCount]()) {
        setFormCount(formCount + 1);
      }
    }
  }

  return (
    <div>
      <Modal
        className={style.StyledModal}
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={modalOpen}
        onClose={onClose}
        slots={{ backdrop: Backdrop }}
      >
        <div className={style.box}>
          <div className={style.header}>
            <button className='close' onClick={onClose}><Close /></button>
            <h2 className={style.title}>{ title }</h2>
          </div>
          <hr />
          <div className={style.respscroll}>
            { 
            (childrenForm && childrenForm[formCount] ) ?
              childrenForm[formCount] 
              :
              ""
            }
            <div className={style.buttonForm}>
              {((formCount !== 0) ?
                <Button className={style.buttonPNS} variant="contained" onClick={onClickPrevButton}> 
                  <NavigateBeforeIcon/>  <span> Précédent</span> 
                </Button>
              : "")}
              {((formCount !== totalFormCount) ? 
                <Button className={style.buttonPNS} variant="contained" type="submit" onClick={onClickNextButton}> 
                  <span>Suivant </span> <NavigateNextIcon/> 
                </Button>
              : "")} 
              {((formCount === totalFormCount) ? 
                <Button className={style.buttonPNS} variant="contained" type="submit" onClick={onFormSubmitSuccess} endIcon={((buttonIcon) ? buttonIcon : <CheckIcon/>)}> 
                  <span>{(buttonText) ? buttonText : 'Terminer'} </span> 
                </Button>
              : "")
              } 
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

const Backdrop = forwardRef<HTMLDivElement, { open?: boolean; className: string }>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div className={clsx({ 'MuiBackdrop-open': open }, className)} ref={ref} {...other} />
  );
});


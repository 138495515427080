import Jrauto from '../../assets/svg/jra-harmonie-logo.png';
import Userbutton from './Userbutton';
import Navbar from './Navbar'
import style from './assets/scss/header.module.scss';

const Header = () => {
    return (
        <>
            <header>
                <div>
                    <nav><Navbar /></nav>
                    <a href='./Accueil'>
                        <img src={Jrauto} alt='Jrauto' />
                    </a>
                    {/* <div>
                        <h1>{renderTitleFromLocation(location.pathname)}</h1>
                    </div> */}
                </div>
                <div className={style.user}>
                    <Userbutton />
                </div>
            </header>
        </>
    )
}

export default Header;
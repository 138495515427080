import { useState } from "react";
import { User } from "../object/User";

export const useUser = () => {
  const getUser = () => {
    const userString = localStorage.getItem("JRA_user") ?? "";
    let user: User = { uid: "", jobUid: "", firstname: "", lastname: "", email: "", photo: "", position: "", authToken: "" };
    if(userString !== "")
      user = JSON.parse(userString)
    return user
  };
  const [ user, setUser ] = useState<User>(getUser());

  const saveUser = (user:User) => {
    localStorage.setItem("JRA_user", JSON.stringify(user))
    setUser(user);
  };

  const removeUser = () => {
    localStorage.removeItem('JRA_user')
  };

  return { user, setUser: saveUser , removeUser};
};

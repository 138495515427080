import { useEffect, useState } from "react";
import { Job } from "../object/Job";

export const useJob = () => {
  const getJob = () => {
    const jobString = localStorage.getItem("JRA_job") ?? "";
    let job:Job = {
                    uid: "",
                    name: ""
                  };
    if(jobString !== "")
      job = JSON.parse(jobString)
    return job
  };

  const [job, setJob] = useState<Job>(getJob());

  const saveJob = (job: Job) => {
    localStorage.setItem("JRA_job", JSON.stringify(job))
    setJob(job);
  };

  const removeJob = () => {
    localStorage.removeItem('JRA_job')
  };

  return { job, setJob: saveJob, removeJob };
};


import React, { useEffect, useState } from 'react';
import { Invest } from '../../services/object/Invest';
import { Category } from '../../services/object/Category';
import { Salepoint } from '../../services/object/Salepoint';
import FormPopup from './FormPopup';
import { Univers } from '../../services/object/Univers';
import { Comment } from '../../services/object/Comment';

interface SalepointPopupChildrensProps {
    errorMessages: Record<string, string>,
    formRefInvest: React.RefObject<HTMLFormElement>,
    invest: Invest|undefined;
    TOCategory: Category[];
    TOSalepoint: Salepoint[];
    categoryUid: string;
    salepointLabel: string[];
    setSalepointLabel: React.Dispatch<React.SetStateAction<string[]>>
    handleFormInvestChange: (property: string, value: string| FileList | number | boolean | null) => void
    filePath : string;
    admin: boolean;
    disabled: boolean;
    TOUnivers: Univers[];
    universUid: string;
    TOComment: Comment[];
    newComment: string;
    setNewComment: React.Dispatch<React.SetStateAction<string>>;
  }
    const investPopupChildrens = ({errorMessages, 
                                    formRefInvest, 
                                    invest, 
                                    categoryUid,
                                    salepointLabel,
                                    setSalepointLabel,
                                    TOCategory,
                                    TOSalepoint,
                                    handleFormInvestChange,
                                    filePath,
                                    admin,
                                    disabled,
                                    TOUnivers,
                                    universUid,
                                    TOComment,
                                    newComment,
                                    setNewComment} :SalepointPopupChildrensProps) => {

                 
    return ([
                <FormPopup errorMessages={errorMessages}
                           formRefInvest={formRefInvest}
                           invest={invest}
                           categoryUid={categoryUid}
                           salepointLabel={salepointLabel}
                           setSalepointLabel={setSalepointLabel}
                           TOCategory={TOCategory}
                           TOSalepoint={TOSalepoint}
                           handleFormInvestChange={handleFormInvestChange}
                           filePath={filePath}
                           admin={admin}
                           disabled={disabled}
                           TOUnivers={TOUnivers}
                           universUid={universUid}
                           TOComment={TOComment}
                           newComment={newComment}
                           setNewComment={setNewComment}/>
           ]
    )
}
export default investPopupChildrens;
import { useState } from "react";
import { Law } from "../object/Law";

export const useLaw = () => {
  const getLaw = () => {
    const lawString = localStorage.getItem("JRA_law") ?? "";
    let law:Law[] = [{
                      uid: "",
                      name: "",
                      description: "",
                      appUid: "",
                      roleUid: "",}];
    if(lawString !== "")
      law = JSON.parse(lawString)
    return law
  };
  const [ law, setLaw ] = useState<Law[]>(getLaw());

  const saveLaw = (law:Law[]) => {
    localStorage.setItem("JRA_law", JSON.stringify(law))
    setLaw(law);
  };

  const removeLaw = () => {
    localStorage.removeItem('JRA_law')
  };
  return { law, setLaw:saveLaw, removeLaw };
};
import style from './crossOrgin.module.scss';
import logo from '../../assets/svg/Logo_JRA_H_NOIR.png';

function AuthCrossOrigin() {
    return (
        <>
            <div className={style.content}>
                <img src={logo} alt="logo"/><br/>
                <div>
                    Connexion en cours... <br/>
                    Vous serez bientôt redirigé.
                </div>
            </div>
            <iframe id="iframe" src="https://harmonie.jra.tools/connexion-crossOrigin" sandbox="allow-forms allow-scripts allow-same-origin allow-top-navigation"></iframe>
        </>
    );
  };
  
  export default AuthCrossOrigin;
export const displayFormErrors = (formRef : React.RefObject<HTMLFormElement>) => {
    const formControls = formRef.current?.elements;
    const errors: Record<string, string> = {};

    if (formControls) {
        for (const control of formControls) {
            if (control instanceof HTMLInputElement || control instanceof HTMLTextAreaElement) {
                if (!control.checkValidity()) {
                    errors[control.name] = control.validationMessage;
                }else{
                    if(Number(control.getAttribute("data-min")!) !== 0 && Number(control.getAttribute("data-min")!) > Number(control.value.replaceAll('€', ""))){
                        errors[control.name] = control.getAttribute("data-error") ?? ""
                    }
                }
            }
        }
    }
    return errors;
};
import clsx from 'clsx';
import style from './tinyModal.module.scss';
import { Modal } from '@mui/base/Modal';
import { ReactComponent as Close } from '../../pages/assets/closeWindows.svg';
import{ forwardRef, useEffect, useState } from 'react';
import { LinearProgress } from '@mui/material';

interface TinyModalComponentProps {
  onClose: () => void;
  modalOpen: boolean;
  title: string|null;
  child:JSX.Element|undefined;
  progess:boolean|undefined;
}

export default function TinyModalComponent( { onClose,
                                              modalOpen,
                                              title = null,
                                              child,
                                              progess}: TinyModalComponentProps) {
  
  useEffect(() => {
  }, [child]);

  return (
    <div>
      <Modal
        className={style.StyledModal}
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={modalOpen}
        onClose={onClose}
        slots={{ backdrop: Backdrop }}
      >
        <div className={style.box}>
            { progess ? <LinearProgress /> : "" }
            <div className={style.newfirstFormUser}>
              <button className='close' onClick={onClose}><Close /></button>
              <h1 className={style.title}>{ title }</h1>
              <div className={style.line}></div>
                <div className={style.respscroll}>
                  { 
                  (child) ?
                    child
                    :
                    ""
                  }
                </div>
            </div>
        </div>
      </Modal>
    </div>
  );
}

const Backdrop = forwardRef<HTMLDivElement, { open?: boolean; className: string }>((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div className={clsx({ 'MuiBackdrop-open': open }, className)} ref={ref} {...other} />
  );
});
  
  
import { useAuth } from "./auth/useAuth";
import useToken from "./auth/useToken";

// export const BASE_API_URL = "http://localhost/dev/jra/jra-demande-investissement/api/index.php/";
export const BASE_API_URL = "https://api-investissement.jra.tools/";
export const HARMONIE_API_URL = "https://api-master.jra.tools/";
export interface ResponseProps{
    success : boolean,
    messages : {
        type : string,
        contentText : string,
        code : number,

    },
    data: any,
    recordsTotal?:number
}

export const Service_Api = (local = true) => {
    let {token} = useToken();
    const { logout } = useAuth();
    
    const request = async (options: any) => {
        let url = BASE_API_URL + options.url;
        if(!local)
            url = HARMONIE_API_URL + options.url;
        if (options.query) {
            const queryString = new URLSearchParams(options.query).toString();
            if (queryString) {
                url += (url.includes('?') ? '&' : '?') + queryString;
            }
        }
        if (token)
            if (options.headers)
                options.headers["Authorization"] = token
            else
                options.headers = { Authorization: token }
        let response;
        let api_response: ResponseProps;
        if (!options.file)
            options.body = JSON.stringify(options.body)
        try {
            response = await fetch(url, {
                method: options.method,
                headers: {
                    ...options.headers,
                },
                body: options.body,
            });
            api_response = await response?.json();

            if (api_response.messages.code === 1000) {
                logout();
                window.location.href = "https://harmonie.jra.tools/login?auth="+btoa("true")+"&app="+btoa(window.location.href);
            }

            return api_response;

        } catch (error) {
            console.log(error);
            // logout();
            // window.location.reload();
        }
    }

    const get = async (url: string, query: string = "", options: {} = {}) => {
        return request({ method: 'GET', url, query, ...options });
    }

    const post = async (url: string, body: {} = {}, options: {} = {}) => {
        return request({ method: 'POST', url, body, ...options });
    }

    const put = async (url: string, body: {} = {}, options: {} = {}) => {
        return request({ method: 'PUT', url, body, ...options });
    }

    const del = async (url: string, body: {} = {}, options: {} = {}) => {
        return request({ method: 'DELETE', url, body, ...options });
    }
    return { get, post, put, del };
}


export default Service_Api;
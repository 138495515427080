import { DataGrid, GridCallbackDetails, GridColDef, GridRowParams, MuiEvent, GridToolbar, frFR, GridFilterModel, GridSortModel, GridToolbarContainer, GridToolbarExport, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { LinearProgress, ListItemText, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useMediaQuery } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ListItem from '@mui/material/ListItem';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Chip from '@mui/material/Chip';
import style from './table.module.scss';
import { Box } from '@mui/system';

interface TableChartProps {
  callBackFunctionGetRowData: (paginationModel: {
                                    page: number;
                                    pageSize: number;
                                }, sort: {}, filter: {}) => Promise<void>
  onRowClick: (params: GridRowParams, event: MuiEvent, details: GridCallbackDetails) => void;
  onCardClick: (salepointUid: string) => void;
  handleDeleteRow: (itemId: string, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  columns: GridColDef<any>[],
  dataRow: any[] | undefined,
  loading :boolean,
  max : number,
  columnVisibility : GridColumnVisibilityModel
}

export interface rowProps {
  rowCount:number;
  row:any[];
}
function TableChart({ dataRow, callBackFunctionGetRowData, columns, columnVisibility, onCardClick, onRowClick, handleDeleteRow, loading, max }: TableChartProps) {

    const [rows, setRows] = useState<any>([]);
    //init rowsCount 
    //init page 
    const [paginationModel, setPaginationModel] = useState({
      page: 0,
      pageSize: 25,
    }); 

    //state for column's filter 
    const [filterModelOptions, setFilterModeOptions] = useState({});
    const onFilterChange = useCallback((filterModel: GridFilterModel) => {
      // Here you save the data you need from the filter model
      setFilterModeOptions({ filterModel: { ...filterModel } });
      setPaginationModel({
        page: 0,
        pageSize: 25,
      })
    }, []);

    //state for column's order 
    const [sortModelOptions, setSortModelOptions] = useState({});
    const onSortChange = useCallback((sortModel: GridSortModel) => {
      // Here you save the data you need from the filter model
      setSortModelOptions({ sortModel: { ...sortModel } });
    }, []);
    
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(columnVisibility);

    //use callback function to get data to hydrate row
    useEffect(() => {
      (async () =>{
        await callBackFunctionGetRowData(paginationModel, sortModelOptions, filterModelOptions);
      })();
    //when one of them change, run callBackFunctionGetRowData (reload data from server)
    }, [paginationModel, sortModelOptions, filterModelOptions]);

    //fromFront
    useEffect(() => {
      //if data.row exist
      if(dataRow){
        setRows(dataRow);
        // setLoading(false);
      }
    }, [dataRow]);


  const [searchInput, setSearchInput] = useState("");
  const isMobile = useMediaQuery('(max-width: 632px)');

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  const filteredData = rows.length >= 1 && rows?.filter((item: any) =>(
    searchInput.length === 0 ||
    Object.values(item).some((value: any) =>
      String(value).toLowerCase().includes(searchInput.toLowerCase())
    ))
  );

  const truncate = (string: string, number: number) => {
    return (string.length > number) ? string.slice(0, number-1) + '...' : string;
  }

  const TableRowsLoader = ( { rowsNum } : { rowsNum: number;}) => {
    return  <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    {columns.map((col, index) => (
                      <TableCell>{col.headerName}</TableCell>
                  ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {[...Array(rowsNum)].map((row, index) => (
                    <TableRow key={index}>
                      {columns.map((col, index) => (
                        <TableCell component="th" scope="row">
                          <Skeleton animation="wave" variant="text" />
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
  };
  
  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: 'Exporter le tableau' },
            button: { variant: 'contained' },
          }}
          csvOptions={{
            fileName: 'Jra-Invest',
            delimiter: ';',
            utf8WithBom: true,
            allColumns: true 
          }}
        />
      </GridToolbarContainer>
    );
  }

  return (
      <>
      { loading ? <LinearProgress /> : "" }
      {
        isMobile ? (
          <div>
            <TextField
              label="Rechercher"
              variant="outlined"
              size="small"
              fullWidth
              onChange={handleChange}
              value={searchInput}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{ zIndex: 0 }}
            />
            {filteredData && filteredData.map((item: any) => (
              <Card key={item.id} variant="outlined" sx={{ marginBlock: '12px', position: 'relative' }}>
                <CardContent>
                  {Object.keys(item).map((key) => (
                    key !== "id" ? (
                      (key === "statut") ?
                        <ListItem key={key} className="card-item">
                          <span>{columns.find((col) => col.field === key)?.headerName || key}:&nbsp;
                            {<Chip label={item[key].statusText} color={item[key].chipColor} variant="outlined" sx={{ width: '90px' }} />}
                          </span>
                        </ListItem>
                        : 
                          <ListItem key={key} className={style.cardItem}>
                            <ListItemText
                              primary={columns.find((col) => col.field === key)?.headerName+": " || key +": "}
                              secondary={truncate(String(item[key]), 50)}
                            />
                          </ListItem>
                    ) : ""
                  ))}
                  <div className={style.editdelete}>
                    <IconButton type="submit" onClick={(e) => { e.stopPropagation(); onCardClick(item.id); }}>
                      <EditIcon fontSize='small' color="primary" />
                    </IconButton>
                    <IconButton onClick={(e) => { e.stopPropagation(); handleDeleteRow(item.id, e); }}>
                      <DeleteIcon fontSize='small' color="primary" />
                    </IconButton>
                  </div>
                </CardContent>
              </Card>
            ))}
          </div>
        ) : (
          (dataRow) ? 
          // si ce n'est pas mobile
          <DataGrid
            disableRowSelectionOnClick 
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            }
            columns={columns}
            pagination={true}
            //set config to server side
              sortingMode="server"
              filterMode="server"
              paginationMode="server"
            //get onPageChange event
              onPaginationModelChange={setPaginationModel}
            //get onFilterChange event
              onFilterModelChange={onFilterChange}
            //get onSortChange event
              onSortModelChange={onSortChange}
            sx={{ zIndex: '0' }}
            rowHeight={40}
            rows={rows}
            //row.lenght
            rowCount={max}
            getRowId={(row) => row.id}
            onRowClick={onRowClick}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            slots={{ toolbar: CustomToolbar }}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            }}
            pageSizeOptions={[25, 50, 100]}
          />
          : 
            <TableRowsLoader rowsNum={20}/>
          )
        }
      </>
      
  );
}

export default TableChart;